<template lang="">
  <div class="p-2">
    <div class="card">
      <h2>Categories</h2>
      <form
        class="w-50"
        v-if="displayType === 'a'"
        @submit.prevent="addCategorie"
      >
        <h3>Nouvelle</h3>
        <div class="form-group">
          <input
            type="text"
            v-model="newCategory.name"
            class="mt-1 form-control"
            placeholder="Nouvelle categorie..."
          />
        </div>
        <div class="form-group">
          <textarea
            type="text"
            v-model="newCategory.description"
            class="mt-1 form-control"
            placeholder="Description..."
          ></textarea>
        </div>
        <div class="row">
          <div class="col-md-3">
            <button type="submit" class="mt-1 btn btn-primary btn-sm">
              Ajouter
            </button>
          </div>
        </div>
      </form>
      <div class="d-flex  mt-1 mb-1 ">
        <div class="form-group ml-2">
          <input
            class="form-check-input"
            type="radio"
            v-model="displayType"
            id="gridRadios1"
            value="v"
          />
          <label class="form-check-label" for="gridRadios1">
            Visualisation
          </label>
        </div>
        <div class="form-group ml-2">
          <input
            class="form-check-input"
            type="radio"
            v-model="displayType"
            name="gridRadios"
            id="gridRadios2"
            value="e"
          />
          <label class="form-check-label" for="gridRadios2">
            Edition
          </label>
        </div>
        <div class="form-group ml-2">
          <input
            class="form-check-input"
            type="radio"
            v-model="displayType"
            name="gridRadios"
            id="Ajouter"
            value="a"
          />
          <label class="form-check-label" for="Ajouter">
            Nouvelle
          </label>
        </div>
      </div>
      <table id="basic-datatable" class="table dt-responsive nowrap w-100">
        <thead>
          <tr>
            <th>#</th>
            <th>Categorie</th>
            <th>Editer</th>
            <th>Supprimer</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in categories" :key="c.id">
            <td>{{ c.id }}</td>
            <td>
              <input
                type="text"
                class="form-control"
                v-model="c.name"
                :disabled="displayType === 'v'"
              />
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm"
                @click="() => edit(c)"
                :disabled="displayType === 'v'"
              >
                <i class="far fa-edit"></i>
                Editer
              </button>
            </td>
            <td>
              <button class="btn btn-danger btn-sm" @click="() => del(c.id)">
                <i class="fas fa-trash"></i>
                Supprimer
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      categories: [],
      displayType: "v",
      newCategory: {
        name: "",
        description: "",
      },
    };
  },
  methods: {
    async loadCategories() {
      await this.$store.dispatch("loadCategories").then((categories) => {
        this.categories = categories;
      });
    },
    async edit(c) {
      await this.$http.put(`/courses/category/${c.id}/update`, c).then(() => {
        this.$store.state.categories.updated = false;
        this.$toast.success("success!");
        this.loadCategories();
      });
    },
    async del(id) {
      if (window.confirm("Etes vous sure de cette action?"))
        await this.$http.delete(`/courses/category/${id}/delete`).then(() => {
          this.$store.state.categories.updated = false;
          this.$toast.success("success!");
          this.loadCategories();
        });
    },
    async addCategorie() {
      await this.$http.post(`/courses/category`, this.newCategory).then(() => {
        this.$store.state.categories.updated = false;
        this.$toast.success("success!");
        this.displayType = "v";
        this.loadCategories();
      });
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      this.loadCategories();
    });
  },
};
</script>
<style lang=""></style>
